import React from "react"
import "typeface-open-sans"
import "./blog-post.css"

export default function PostDDO() {
  return (
    <div className="blog-post">
      <a className="home-link" href="/">Home</a>
      <h1>Upload Your Reading Life</h1>
      <span class="blog-post-date">February 2021</span>

      <p>
        A few weeks ago I started a new habit of publishing book excerpts that I find interesting
        at <a href="https://jmdavril.com/books">jmdavril.com/books</a>.
      </p>

      <p>
        I thought it would be valuable to collect these excerpts over a long period of time, say 10 years.
        I love reading and I have been wanting to commit to a web project with a long-term horizon.
      </p>

      <p>
        As I read physical books, I was immediately annoyed by the rewriting of the excerpts with my keyboard.
        So I took on building a simple mobile app to take care of the writing for me. An app for converting pictures of
        book pages into structured text, using OCR, and for publishing the outputs to my personal static website.
      </p>

      <p>
        The code for the mobile app is open source and available
        at <a href="https://github.com/jmdavril/numinosum">github.com/jmdavril/numinosum</a>.
        It is built with <a href="https://ionicframework.com/docs/react">Ionic 5 in React</a> and
        uses <a href="https://github.com/ionic-team/capacitor">Capacitor</a> to call the camera.
        The main js dependencies are <a href="https://github.com/DominicTobias/react-image-crop">react-image-crop</a>,
        an image cropper with a nice touch interaction,
        and <a href="https://github.com/naptha/tesseract.js">tesseract.js</a>,
        a javascript port of the <a href="https://github.com/tesseract-ocr/tesseract">Tesseract OCR engine</a>.
      </p>

      <p>
        I use the app to post the excerpts to an headless CMS API built
        with <a href="https://strapi.io">Strapi</a>.
        My personal website is built
        with <a href="https://github.com/gatsbyjs/gatsby">Gatsby</a> and consumes
        that API to list the excerpts at <a href="https://jmdavril.com/books">jmdavril.com/books</a>.
      </p>
    </div>
  )
}
